<template>
  <div class="container">
    <!-- 左边柱状图 -->
    <div class="chart" ref="barChart"></div>
    <!-- 右边饼状图 -->
    <div class="chart" ref="pieChart"></div>
  </div>
</template>

<script>
// 引入 ECharts 库
import * as echarts from 'echarts';
import { findClassData, findClassPieDate } from "../../network/reply";

export default {
  name: 'Charts',
  data() {
    return {
      barChartNameData: [],
      barChartValueData: [],
      pieChartData: []
    };
  },
  mounted() {
    // 发送请求获取数据
    findClassData().then(res => {
      this.barChartNameData = res.name;
      this.barChartValueData = res.value;
      this.renderCharts();
    });
    findClassPieDate().then(res => {
      console.log(res.data);
      this.pieChartData = res.data;
      this.renderCharts();
    });
  },
  methods: {
    renderCharts() {
      // 获取柱状图和饼状图容器
      const barChartContainer = this.$refs.barChart;
      const pieChartContainer = this.$refs.pieChart;

      // 初始化柱状图和饼状图实例
      const barChart = echarts.init(barChartContainer);
      const pieChart = echarts.init(pieChartContainer);

      // 设置柱状图和饼状图的配置项
      barChart.setOption(this.getBarChartOptions());
      pieChart.setOption(this.getPieChartOptions());

      // 添加鼠标移动事件监听器
      // barChart.on('mousemove', params => {
      //   if (params.data) {
      //     console.log('柱状图数据:', params.data);
      //   }
      // });

      // pieChart.on('mousemove', params => {
      //   if (params.data) {
      //     console.log('饼状图数据:', params.data);
      //   }
      // });
    },
    getBarChartOptions() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.barChartNameData,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '使用时长',
            type: 'bar',
            barWidth: '60%',
            data: this.barChartValueData
          }
        ],
        title: [{
          text: '自习室使用总时间汇总 单位/h',
          left: '50%',
          // top: '95%',
          textAlign: 'center',
          textStyle: {
            color: '#666',
            fontSize: 18
          }
        }],
        // 柱状图配置项
      };
    },
    getPieChartOptions() {
      return {
        // 饼状图配置项
        title: [{
          text: '不同自习室使用频率',
          left: '50%',
          textAlign: 'center',
          textStyle: {
            color: '#666',
            fontSize: 18
          }
        }],
        legend: {
          orient: 'vertical',
          left: 'right'
        },
        series: [{
          name: '自习室',
          type: 'pie',
          radius: '55%',
          data: this.pieChartData.map(item => ({ value: item.value, name: item.name }))
        }],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: ({d}%)'
        },
      };
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
}

.chart {
  flex: 1;
  height: 400px;
}
</style>
